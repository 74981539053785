import React from "react";
import { Link } from "react-router-dom";
import Can from "../Auth/Can";
import PropTypes from "prop-types";
import ActionEdit from "../Shared/List/ActionEdit";
import ActionDelete from "../Shared/List/ActionDelete";
import Checkbox from "../Shared/List/Checkbox";

class User extends React.Component {
    handleDeactivateClick = () => {
        alert("deactivated");
    };

    render = () => {
        const user = this.props.user;
        return (
            <tr className="user">
                <td>
                    {user.firstName} {user.lastName}
                </td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td className="center">
                    {(user.isAdmin || user.isSuperAdmin) && <Checkbox />}
                </td>
                <td className="center">
                    <Can
                        user={this.props.currentUser}
                        perform={"users:update"}
                        yes={() => <ActionEdit to="/users/edit" id={user.id} />}
                    />
                    <Can
                        user={this.props.currentUser}
                        perform={"users:block"}
                        yes={() => (
                            <Link
                                className="action-icon"
                                to={`/users/block/${user.id}`}
                            >
                                <ion-icon name="thumbs-down" />
                            </Link>
                        )}
                    />
                    <Can
                        user={this.props.currentUser}
                        perform={"users:delete"}
                        yes={() => <ActionDelete />}
                    />
                </td>
            </tr>
        );
    };
}

User.propTypes = {
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default User;
