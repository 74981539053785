import React from "react";
import Can from "../Auth/Can";
import NoResultsRow from "../Shared/NoResultsRow/NoResultsRow";
import PageTitle from "../Shared/PageTitle/PageTitle";
import User from "./User";
import PageLayout from "../Shared/PageLayout/PageLayout";
import ActionCreate from "../Shared/List/ActionCreate";

class UserList extends React.Component {
    handleFilterTextChange = () => {};
    handleCompanyTextChange = () => {};

    renderUserList = ({ currentUser }) => {
        const filter = "";
        const data = require("../../users");

        return (
            <div className="user-list">
                <PageTitle title="Manage Users">
                    <Can
                        user={currentUser}
                        perform="users:create"
                        yes={() => <ActionCreate to="/users/new" name="User" />}
                    />
                </PageTitle>
                <div className="filter-wrapper form-inline">
                    <div className="form-group">
                        <Can
                            user={currentUser}
                            perform="company:manage"
                            yes={() => (
                                <span className="company-filter">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        name="company"
                                        id="company"
                                        value="Bob's Autos"
                                        onChange={this.handleCompanyTextChange}
                                        className="form-control"
                                    />
                                </span>
                            )}
                        />
                        <label htmlFor="filter">Filter</label>
                        <input
                            name="filter"
                            id="filter"
                            value={filter}
                            onChange={this.handleFilterTextChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th style={{ textAlign: "center" }}>Is Admin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.default.length > 0 ? (
                            data.default.map((user) => (
                                <User
                                    key={user.id}
                                    user={user}
                                    currentUser={currentUser}
                                />
                            ))
                        ) : (
                            <NoResultsRow />
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="users:list"
                callback={this.renderUserList}
            />
        );
    };
}

export default UserList;
