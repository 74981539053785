const applications = [
    {
        id: 1,
        name: "Automatic",
        roles: [
            {
                id: 17,
                name: "User",
                diOnly: false,
            },
            {
                id: 1,
                name: "Admin",
                diOnly: false,
            },
        ],
    },
    {
        id: 2,
        name: "Conversations",
        roles: [
            {
                id: 16,
                name: "User",
                diOnly: false,
            },
            {
                id: 2,
                name: "Admin",
                diOnly: false,
            },
            {
                id: 3,
                name: "SuperAdmin",
                diOnly: true,
            },
        ],
    },
    {
        id: 3,
        name: "Fuel",
        roles: [
            {
                id: 15,
                name: "User",
                diOnly: false,
            },
            {
                id: 4,
                name: "Admin",
                diOnly: false,
            },
        ],
    },
    {
        id: 4,
        name: "Metal",
        roles: [
            {
                id: 5,
                name: "Admin",
                diOnly: false,
            },
            {
                id: 6,
                name: "Finance Manager",
                diOnly: false,
            },
            {
                id: 7,
                name: "Finance Approver",
                diOnly: false,
            },
            {
                id: 8,
                name: "Feature Manager",
                diOnly: false,
            },
        ],
    },
    {
        id: 5,
        name: "Online Shopper",
        roles: [
            {
                id: 9,
                name: "Admin",
                diOnly: false,
            },
        ],
    },
    {
        id: 6,
        name: "Prizm",
        roles: [
            {
                id: 14,
                name: "User",
                diOnly: false,
            },
            {
                id: 10,
                name: "Admin",
                diOnly: false,
            },
        ],
    },
    {
        id: 7,
        name: "Role Management",
        roles: [
            {
                id: 11,
                name: "Admin",
                diOnly: false,
            },
            {
                id: 12,
                name: "SuperAdmin",
                diOnly: true,
            },
        ],
    },
    {
        id: 7,
        name: "Website",
        roles: [
            {
                id: 13,
                name: "Admin",
                diOnly: false,
            },
        ],
    },
];

export default applications;
