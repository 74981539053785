import React from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import Input from "../../components/Shared/Form/Input";
import Checkbox from "../../components/Shared/Form/Checkbox";
import * as Yup from "yup";
import "../../../node_modules/prismjs/themes/prism-dark.css";
import "./RuleForm.css";
import CancelButton from "../Shared/Form/CancelButton";
import SaveButton from "../Shared/Form/SaveButton";

const validationSchema = {
    name: Yup.string().required(),
    isActive: Yup.bool(),
    code: Yup.string().required(),
};

class RuleForm extends React.Component {
    state = { code: "" };
    testScript = () => {
        alert("testing script");
    };
    render = () => {
        const { rule, handleSubmit } = this.props;
        return (
            <Formik
                initialValues={{ rule }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                render={() => {
                    return (
                        <Form>
                            <fieldset className="contact-info">
                                <legend>Rule</legend>
                                <Input
                                    name="name"
                                    label="Name"
                                    value={rule.name}
                                />
                                <Checkbox
                                    name="idActive"
                                    value={rule.isActive}
                                    label="Active"
                                    labelClass="col-sm-7"
                                    inputClass="col-sm-5"
                                />
                                <Editor
                                    className="language-javascript"
                                    value={rule.code}
                                    onValueChange={(code) =>
                                        this.setState({ code })
                                    }
                                    highlight={(code) =>
                                        Prism.highlight(
                                            code,
                                            Prism.languages.javascript
                                        )
                                    }
                                    padding={10}
                                    style={{
                                        fontFamily:
                                            '"Source Code Pro", "Fira code", "Fira Mono", monospace',
                                    }}
                                />
                            </fieldset>
                            <div className="button-wrapper">
                                <CancelButton to="/rules" />
                                <button
                                    type="button"
                                    className="test btn btn-raised"
                                    onClick={this.testScript}
                                >
                                    Test
                                </button>
                                <SaveButton />
                            </div>
                        </Form>
                    );
                }}
            />
        );
    };
}

RuleForm.propTypes = {
    rule: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default RuleForm;
