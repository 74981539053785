import React from "react";
import Can from "../Auth/Can";
import PropTypes from "prop-types";
import ActionEdit from "../Shared/List/ActionEdit";
import ActionActivate from "../Shared/List/ActionActivate";

class Group extends React.Component {
    handleActivateClick = () => {
        alert("activated");
    };

    handleDeactivateClick = () => {
        alert("deactivated");
    };

    render = () => {
        const group = this.props.group;

        return (
            <tr className="group">
                <td>{group.name}</td>
                <td>{group.url}</td>
                <td>{group.accountId}</td>
                <td style={{ textAlign: "center" }}>
                    <Can
                        user={this.props.currentUser}
                        perform="group:update"
                        yes={() => (
                            <ActionEdit to="/group/edit" id={group.id} />
                        )}
                    />
                    <Can
                        user={this.props.currentUser}
                        perform="group:activate"
                        yes={() => (
                            <ActionActivate
                                isActive={true}
                                handleActivate={this.handleActivateClick}
                                handleDeactivate={this.handleDeactivateClick}
                            />
                        )}
                    />
                </td>
            </tr>
        );
    };
}

Group.propTypes = {
    group: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default Group;
