const companies = [
    {
        id: 1,
        name: "Acura of Rochester",
        url: "http://www.acuraofrochester.com",
        accountId: "0013600000OEU8YAAX",
        parent: "Garber Automotive Group",
    },
    {
        id: 2,
        name: "Delray Buick GMC",
        url: "https://www.garberbuickgmc.com/",
        accountId: "0013600000IikdRAAR",
        parent: "Garber Automotive Group",
    },
    {
        id: 3,
        name: "Garber Chrysler Dodge Jeep Ram (Michigan)",
        url: "http://www.garberchryslerdodgeramjeep.com/",
        accountId: "0013600000wY2W8AAK",
        parent: "Garber Automotive Group",
    },
    {
        id: 4,
        name: "Garber Fox Lake Toyota",
        url: "http://www.garbertoyota.com",
        accountId: "0013600000NTBjdAAH",
        parent: "Garber Automotive Group",
    },
    {
        id: 5,
        name: "Garber Honda",
        url: "www.garberhonda.com",
        accountId: "0013600000NTBZxAAP",
        parent: "Garber Automotive Group",
    },
    {
        id: 6,
        name: "Garber Nissan",
        url: "http://www.garbernissan.net/",
        accountId: "0013600000Gfz0QAAR",
        parent: "Garber Automotive Group",
    },
    {
        id: 7,
        name: "Go Garber",
        url: "http://www.gogarber.com/",
        accountId: "0013600000NTBjxAAH",
        parent: "Garber Automotive Group",
    },
    {
        id: 8,
        name: "xHilton Head Buick GMC Cadillac",
        url: "http://www.acuraofrochester.com",
        accountId: "0013600000OEU8YAAX",
        parent: "Garber Automotive Group",
    },
    {
        id: 9,
        name: "Nissan Bradenton",
        url: "https://www.nissanofbradenton.com",
        accountId: "0013600001ijUQfAAM",
        parent: "Garber Automotive Group",
    },
    {
        id: 10,
        name: "Porsche Rochester",
        url: "https://porscheofrochester.com/",
        accountId: "0013600001jmTdrAAE",
        parent: "Garber Automotive Group",
    },
    {
        id: 11,
        name: "Rightway Automotive",
        url: "http://www.rightway.com/",
        accountId: "00136000013mfIeAAI",
        parent: "Garber Automotive Group",
    },
    {
        id: 12,
        name: "Rochester Portal Site",
        url: "www.gogarber.com",
        accountId: "0013600000SDKEuAAP",
        parent: "Garber Automotive Group",
    },
    {
        id: 13,
        name: "Garber Automall",
        url: "https://www.garberautomall.com",
        accountId: "0013600000pw0t4AAA",
        parent: "Garber Automotive Group",
    },
    {
        id: 14,
        name: "xSunrise Chevrolet",
        url: "http://www.acuraofrochester.com",
        accountId: "0013600000OEU8YAAX",
        parent: "Garber Automotive Group",
    },
    {
        id: 15,
        name: "Garber Buick GMC",
        url: "https://www.garberbuickgmc.com/",
        accountId: "0013600000IikdRAAR",
        parent: "Garber Automotive Group",
    },
    {
        id: 16,
        name: "Garber Buick Saginaw",
        url: "https://www.garberbuick.com/",
        accountId: "0013600000s77uvAAA",
        parent: "Garber Automotive Group",
    },
    {
        id: 17,
        name: "Garber CDJR Green Cove Springs",
        url: "http://www.garberchryslerdodgejeep.com/\n",
        accountId: "00136000013mfXUAAY",
        parent: "Garber Automotive Group",
    },
    {
        id: 18,
        name: "Garber Chevrolet Linwood",
        url: "https://www.garberlinwood.com/",
        accountId: "0013600000s772hAAA",
        parent: "Garber Automotive Group",
    },
    {
        id: 19,
        name: "Garber Chevrolet Midland",
        url: "https://www.garbermidland.com/",
        accountId: "0013600000s75oeAAA",
        parent: "Garber Automotive Group",
    },
    {
        id: 20,
        name: "Garber Chevrolet Saginaw",
        url: "https://www.garberchevroletsaginaw.com/",
        accountId: "0013600001Kl3kHAAR",
        parent: "Garber Automotive Group",
    },
    {
        id: 21,
        name: "Garber Chrysler Dodge Jeep Ram (Florida)",
        url: "https://www.garberchryslerdodgejeep.com/",
        accountId: "0013600000buLlGAAU",
        parent: "Garber Automotive Group",
    },
];

export default companies;
