import React from "react";
import PageLayout from "../Shared/PageLayout/PageLayout";
import PageTitle from "../Shared/PageTitle/PageTitle";
import Can from "../Auth/Can";
import Group from "../Group/Group";
import NoResultsRow from "../Shared/NoResultsRow/NoResultsRow";
import ActionCreate from "../Shared/List/ActionCreate";

class GroupList extends React.Component {
    handleFilterTextChange = () => {};

    renderGroupList = ({ currentUser, ...rest }) => {
        const filter = "";
        const data = require("../../groups");

        return (
            <div className="group-list">
                <PageTitle title="Manage Group">
                    <Can
                        user={currentUser}
                        perform="group:create"
                        yes={() => (
                            <ActionCreate to="/group/new" name="Group" />
                        )}
                    />
                </PageTitle>
                <div className="filter-wrapper form-inline">
                    <div className="form-group">
                        <label htmlFor="filter">Filter</label>
                        <input
                            name="filter"
                            id="filter"
                            value={filter}
                            onChange={this.handleFilterTextChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Account</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.default.length > 0 ? (
                            data.default.map((group) => (
                                <Group
                                    key={group.id}
                                    group={group}
                                    currentUser={currentUser}
                                />
                            ))
                        ) : (
                            <NoResultsRow />
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="group:manage"
                callback={this.renderGroupList}
            />
        );
    };
}

export default GroupList;
