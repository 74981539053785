import React from "react";
import PageTitle from "../../components/Shared/PageTitle/PageTitle";
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import { Form, Formik } from "formik";
import UserRolesFormPartial from "./UserRolesFormPartial";
import * as Yup from "yup";
import CancelButton from "../Shared/Form/CancelButton";
import SaveButton from "../Shared/Form/SaveButton";
import InputDisabled from "../Shared/Form/InputDisabled";

const validationSchema = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    phoneNumber: Yup.string().matches(/[0-9]*/),
    isAdmin: Yup.bool(),
};

class UserCreate extends React.Component {
    handleSubmit = () => {
        alert("handleSubmit");
    };

    renderUserForm = () => {
        const user = {
            id: 2,
            name: "Michal Gmail",
            firstName: "Michal",
            lastName: "Carson",
            email: "michal.carson@gmail.com",
            roles: ["admin"],
            phoneNumber: "1231231234",
            isAdmin: true,
            isSuperAdmin: false,
        };
        return (
            <div className="user-form add-form">
                <PageTitle title="Edit User" />
                <Formik
                    initialValues={{ user }}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                    render={() => {
                        return (
                            <Form>
                                <fieldset className="contact-info">
                                    <legend>Contact Information</legend>
                                    <InputDisabled
                                        name="firstName"
                                        label="First Name"
                                        value={user.firstName}
                                    />
                                    <InputDisabled
                                        name="lastName"
                                        label="Last Name"
                                        value={user.lastName}
                                    />
                                    <InputDisabled
                                        name="email"
                                        label="Email"
                                        value={user.email}
                                        type="email"
                                    />
                                </fieldset>
                                <UserRolesFormPartial user={user} />
                                <div className="button-wrapper">
                                    <CancelButton to="/users" />
                                    <SaveButton />
                                </div>
                            </Form>
                        );
                    }}
                />
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="users:update"
                callback={this.renderUserForm}
            />
        );
    };
}

export default UserCreate;
