import React from "react";
import { AuthConsumer } from "../../../authContext";
import Login from "../../../components/Auth/Login";
import Logout from "../../../components/Auth/Logout";
import Can from "../../../components/Auth/Can";
import NavigationHeader from "../../../components/Navigation/NavigationHeader";
import NavigationMenu from "../../../components/Navigation/NavigationMenu";
import PropTypes from "prop-types";
import "./PageLayout.css";

class PageLayout extends React.Component {
    renderContent = (user) => {
        return (
            <Can
                user={user}
                perform={this.props.permission}
                yes={() => (
                    <div className="page-content-div">
                        <NavigationHeader user={user} />
                        <div className="container page-content-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <NavigationMenu user={user} />
                                </div>
                                <div className="col-sm-9">
                                    {this.props.callback({ currentUser: user })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                no={() => (
                    <div>
                        <h2>Dealer Inspire User Management</h2>
                        <p>No permissions for this application.</p>
                        <Logout />
                    </div>
                )}
            />
        );
    };

    render = () => {
        return (
            <AuthConsumer>
                {({ authenticated, user }) =>
                    authenticated ? (
                        this.renderContent(user)
                    ) : (
                        <div>
                            <h2>Dealer Inspire User Management</h2>
                            <p>Please login.</p>
                            <Login />
                        </div>
                    )
                }
            </AuthConsumer>
        );
    };
}

PageLayout.propTypes = {
    permission: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
};

export default PageLayout;
