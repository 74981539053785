import PropTypes from "prop-types";
import React from "react";

const Input = ({
    name,
    value,
    label,
    type = "text",
    labelClass = "",
    inputClass = "",
}) => (
    <div className="form-group">
        <div className={inputClass}>
            <label htmlFor={name} className={`control-label ${labelClass}`}>
                {label}
            </label>
            <input
                name={name}
                placeholder={value}
                type={type}
                className={`form-control form-control-disabled ${inputClass}`}
                disabled={true}
            />
        </div>
    </div>
);

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    type: PropTypes.oneOf(["text", "email", "password", "tel", "url"]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
};

export default Input;
