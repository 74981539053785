import React from "react";
import Can from "../Auth/Can";

const NavigationMenu = ({ user }) => (
    <nav className="nav">
        <ul className="navigation-menu nav flex-column">
            <Can
                user={user}
                perform="group:manage"
                yes={() => (
                    <li className="nav-item">
                        <a className="nav-link" href="/groups">
                            Groups
                        </a>
                    </li>
                )}
            />
            <Can
                user={user}
                perform="company:manage"
                yes={() => (
                    <li className="nav-item">
                        <a className="nav-link" href="/companies">
                            Companies
                        </a>
                    </li>
                )}
            />
            <Can
                user={user}
                perform="client:manage"
                yes={() => (
                    <li className="nav-item">
                        <a className="nav-link" href="/applications">
                            Applications
                        </a>
                    </li>
                )}
            />
            <Can
                user={user}
                perform="users:list"
                yes={() => (
                    <li className="nav-item">
                        <a className="nav-link" href="/users">
                            Users
                        </a>
                    </li>
                )}
            />
            <Can
                user={user}
                perform="rule:manage"
                yes={() => (
                    <li className="nav-item">
                        <a className="nav-link" href="/rules">
                            Rules
                        </a>
                    </li>
                )}
            />
        </ul>
    </nav>
);

export default NavigationMenu;
