import rules from "../../rbac-rules";
import PropTypes from "prop-types";

const check = (user, role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    if (permissions.static && permissions.static.includes(action)) {
        // static permission is granted to this role
        return true;
    }

    if (permissions.dynamic && permissions.dynamic[action]) {
        // evaluate dynamic permission
        const condition = permissions.dynamic[action];
        return condition(user, data);
    }

    return false;
};

const checkAllRoles = (user, action, data) => {
    let can = false;
    if (user && user.roles) {
        can = user.roles.some((role) => {
            return check(user, role, action, data);
        });
    }

    return can;
};

const Can = (props) =>
    checkAllRoles(props.user, props.perform, props.data)
        ? props.yes()
        : props.no();

Can.propTypes = {
    user: PropTypes.object.isRequired,
    perform: PropTypes.string.isRequired,
    data: PropTypes.object,
    yes: PropTypes.func,
    no: PropTypes.func,
};

Can.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default Can;
