import React from "react";
import PropTypes from "prop-types";

const SaveButton = ({ text = "Save Changes", className = "" }) => {
    return (
        <button
            type="submit"
            className={`save btn btn-primary btn-raised ${className}`}
        >
            {text}
        </button>
    );
};

SaveButton.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};

export default SaveButton;
