import React from "react";
import Can from "../Auth/Can";
import PropTypes from "prop-types";
import ActionEdit from "../Shared/List/ActionEdit";
import ActionDelete from "../Shared/List/ActionDelete";

class Application extends React.Component {
    handleDeactivateClick = () => {
        alert("deactivated");
    };

    render = () => {
        const application = this.props.application;

        return (
            <tr className="application">
                <td>{application.name}</td>
                <td style={{ textAlign: "center" }}>
                    <Can
                        user={this.props.currentUser}
                        perform="application:update"
                        yes={() => (
                            <ActionEdit
                                to="/applications/edit"
                                id={application.id}
                            />
                        )}
                    />
                    <Can
                        user={this.props.currentUser}
                        perform="application:delete"
                        yes={() => <ActionDelete />}
                    />
                </td>
            </tr>
        );
    };
}

Application.propTypes = {
    application: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default Application;
