import React from "react";
import kebabCase from "kebab-case";
import PropTypes from "prop-types";
import Checkbox from "../../components/Shared/Form/Checkbox";

class UserRolesFormPartial extends React.Component {
    renderRole = (application, roleName) => {
        const isA =
            "is" + roleName.replace(/\s/g, "") + application.replace(/\s/g, "");

        if (!this.props.user.roles[application]) {
            this.props.user.roles[application] = {};
        }

        if (!this.props.user.roles[application][roleName]) {
            this.props.user.roles[application][roleName] = false;
        }

        return (
            <Checkbox
                name={isA}
                value={this.props.user.roles[application][roleName]}
                label={roleName}
                labelClass="col-sm-7"
                inputClass="col-sm-5"
            />
        );
    };

    renderApplication = (role) => {
        const classs = kebabCase(role.application);

        return (
            <fieldset className={`${classs} application-access`}>
                <legend>{role.application}</legend>
                {role.roles.map((roleName) =>
                    this.renderRole(role.application, roleName)
                )}
            </fieldset>
        );
    };

    render = () => {
        const roles = [
            {
                application: "Conversations",
                roles: ["Administrator"],
            },
            {
                application: "Fuel",
                roles: ["Administrator", "Manager"],
            },
            {
                application: "Metal",
                roles: [
                    "Administrator",
                    "Finance Manager",
                    "Finance Approver",
                    "Feature Editor",
                ],
            },
            {
                application: "Online Shopper",
                roles: ["Administrator"],
            },
            {
                application: "Role Management",
                roles: ["Administrator"],
            },
        ];
        return roles.map((role) => this.renderApplication(role));
    };
}

UserRolesFormPartial.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserRolesFormPartial;
