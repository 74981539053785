import React from "react";

export const NoResultsRow = ({ numCols = 42, text = "No Results Found" }) => (
    <tr>
        <td colSpan={numCols} style={{ textAlign: "center" }}>
            {text}
        </td>
    </tr>
);

export default NoResultsRow;
