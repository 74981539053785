import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CancelButton = ({ to, text = "Cancel", className = "" }) => {
    return (
        <Link to={to} className={`cancel ${className}`}>
            {text}
        </Link>
    );
};

CancelButton.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string,
    className: PropTypes.string,
};

export default CancelButton;
