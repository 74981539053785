import React from "react";
import ErrorBoundary from "react-error-boundary";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ApplicationEdit from "./components/Application/ApplicationEdit";
import ApplicationList from "./components/Application/ApplicationList";
import Auth from "./components/Auth/Auth";
import CompanyList from "./components/Company/CompanyList";
import GroupList from "./components/Group/GroupList";
import RouteNotFound from "./components/Navigation/RouteNotFound";
import UserCreate from "./components/User/UserCreate";
import UserEdit from "./components/User/UserEdit";
import UserList from "./components/User/UserList";
import RuleEdit from "./components/Rule/RuleEdit";
import RuleList from "./components/Rule/RuleList";
import CallbackPage from "./pages/callback";
import ScriptTest from "./pages/scripttest";

class App extends React.Component {
    renderRouter = () => {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={UserList} />
                    <Route
                        exact
                        path="/applications"
                        component={ApplicationList}
                    />
                    <Route
                        path="/applications/edit/:id"
                        component={ApplicationEdit}
                    />
                    <Route path="/callback" component={CallbackPage} />
                    <Route path="/companies" exact component={CompanyList} />
                    <Route path="/groups" exact component={GroupList} />
                    <Route path="/users" exact component={UserList} />
                    <Route path="/users/edit/:id" component={UserEdit} />
                    <Route path="/users/new" component={UserCreate} />
                    <Route path="/rules" exact component={RuleList} />
                    <Route path="/rules/edit/:id" component={RuleEdit} />
                    <Route path="/script" exact component={ScriptTest} />
                    <Route component={RouteNotFound} />
                </Switch>
            </BrowserRouter>
        );
    };

    render() {
        return (
            <div className="container">
                <ErrorBoundary>
                    <Auth>{this.renderRouter()}</Auth>
                </ErrorBoundary>
            </div>
        );
    }
}

export default App;
