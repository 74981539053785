import PropTypes from "prop-types";
import React from "react";
import { ErrorMessage, Field } from "formik";

const Checkbox = ({ labelClass, inputClass, uid = "", label, value, name }) => (
    <div className="form-group">
        <Field
            id={`toggle-${name}${uid}`}
            name={name}
            className={`form-check-input ${inputClass}`}
            type="checkbox"
            value={value}
        />
        <label
            htmlFor={`toggle-${name}${uid}`}
            className={`form-check-label ${labelClass}`}
        >
            {label}
        </label>
        <ErrorMessage name={name} />
    </div>
);

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    uid: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
};

export default Checkbox;
