import React from "react";
import { Form, Formik } from "formik";
import UserRolesFormPartial from "./UserRolesFormPartial";
import Input from "../../components/Shared/Form/Input";
import PageTitle from "../../components/Shared/PageTitle/PageTitle";
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import CancelButton from "../Shared/Form/CancelButton";
import SaveButton from "../Shared/Form/SaveButton";
import * as Yup from "yup";

const validationSchema = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
};

class UserCreate extends React.Component {
    handleSubmit = () => {
        alert("User receives invitation email.");
    };

    renderUserForm = () => {
        const user = {
            firstName: "",
            lastName: "",
            email: "",
            isAdmin: false,
            roles: { isAdmin: false },
        };
        return (
            <div className="user-form add-form">
                <PageTitle title="Add New User" />
                <Formik
                    initialValues={{ user }}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                    render={() => {
                        return (
                            <Form>
                                <fieldset className="contact-info">
                                    <legend>Contact Information</legend>
                                    <Input
                                        name="firstName"
                                        label="First Name"
                                        value={user.firstName}
                                    />
                                    <Input
                                        name="lastName"
                                        label="Last Name"
                                        value={user.lastName}
                                    />
                                    <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={user.email}
                                    />
                                </fieldset>
                                <UserRolesFormPartial user={user} />
                                <div className="button-wrapper">
                                    <CancelButton to="/users" />
                                    <SaveButton />
                                </div>
                            </Form>
                        );
                    }}
                />
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="users:create"
                callback={this.renderUserForm}
            />
        );
    };
}

export default UserCreate;
