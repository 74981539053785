const users = [
    {
        id: 1,
        name: "Michal Carson",
        firstName: "Michal",
        lastName: "Carson",
        email: "mcarson@dealerinspire.com",
        roles: ["superAdmin"],
        phoneNumber: "1231231234",
        isAdmin: true,
        isSuperAdmin: true,
    },
    {
        id: 2,
        name: "Michal Gmail",
        firstName: "Michal",
        lastName: "Carson",
        email: "michal.carson@gmail.com",
        roles: ["admin"],
        phoneNumber: "1231231234",
        isAdmin: true,
        isSuperAdmin: false,
    },
    {
        id: 3,
        name: "Bob Bondurant",
        firstName: "Bob",
        lastName: "Bondurant",
        email: "bob@bondurant.com",
        roles: ["visitor"],
        phoneNumber: "1231231234",
        isAdmin: false,
        isSuperAdmin: false,
    },
    {
        id: 4,
        name: "Bob Vila",
        firstName: "Bob",
        lastName: "Vila",
        email: "bob@bobvila.com",
        roles: ["visitor"],
        phoneNumber: "1231231234",
        isAdmin: false,
        isSuperAdmin: false,
    },
];

export default users;
