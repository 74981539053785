import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ title, children }) => (
    <div className="page-title">
        <header>
            <div>
                <h2>{title}</h2>
            </div>
            {children ? <div>{children}</div> : null}
        </header>
        <hr className="fancy" />
    </div>
);

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default PageTitle;
