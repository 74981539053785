import React from "react";

const ScriptTest = () => {
    const util = require("util");
    const vm = require("vm");

    global.globalVar = 3;

    const sandbox = { globalVar: 1 };
    const context = vm.createContext(sandbox);
    let result = "";

    const script1 = `globalVar *= 2; 
    returnVal = true;`; // return value will be true

    // eslint-disable-next-line no-unused-vars
    const script2 = `const line = 1;
    const line2 = 2;
    throw new Error("boom");`;

    try {
        result = vm.runInContext(script1, context);
    } catch (e) {
        return (
            <div>
                <p>
                    Error
                    <br /> {util.inspect(e)}
                </p>
                {e.name && (
                    <p>
                        Name
                        <br /> {e.name}
                    </p>
                )}
                {e.message && (
                    <p>
                        Message
                        <br /> {e.message}
                    </p>
                )}
                <p>
                    Stack
                    <br /> {e.stack}
                </p>
                {e.code && (
                    <p>
                        Code
                        <br /> {e.code}
                    </p>
                )}
            </div>
        );
    }

    return (
        <div>
            <p>
                Context
                <br /> {util.inspect(context)}
            </p>
            <p>
                Global
                <br /> {util.inspect(global.globalVar)}
            </p>
            <p>
                Return
                <br /> {util.inspect(result)}
            </p>
        </div>
    );
};

export default ScriptTest;
