import React from "react";
import { AuthConsumer } from "../../authContext";

const loginSuper = (setSession) => {
    setSession({
        accessToken: "fake|1234",
        sub: "fake|1234",
        name: "Michal Carson",
        given_name: "Michal",
        family_name: "Carson",
        email: "mcarson@dealerinspire.com",
        "https://dealerinspire/roles": ["superAdmin"],
        picture:
            "https://lh6.googleusercontent.com/-U0ODQKtS6eQ/AAAAAAAAAAI/AAAAAAAAAAw/SJDYq1dfxQU/photo.jpg",
    });
};

const Login = () => (
    <AuthConsumer>
        {({ initiateLogin, setSession }) => (
            <div>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={initiateLogin}
                >
                    Login
                </button>
                &nbsp;
                <button className="btn btn-sm" onClick={loginSuper(setSession)}>
                    Login as super
                </button>
            </div>
        )}
    </AuthConsumer>
);

export default Login;
