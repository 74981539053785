import React from "react";
import auth0 from "auth0-js";

import { AUTH_CONFIG } from "../../auth0-variables";
import { AuthProvider } from "../../authContext";

const auth = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://${AUTH_CONFIG.domain}/userinfo`,
    responseType: "token id_token",
});

class Auth extends React.Component {
    state = {
        authenticated: false,
        user: {
            roles: ["visitor"],
        },
        accessToken: "",
    };

    initiateLogin = () => {
        auth.authorize();
    };

    logout = () => {
        this.setState({
            authenticated: false,
            user: {
                roles: ["visitor"],
            },
            accessToken: "",
        });

        window.history.replace("/");
    };

    handleAuthentication = () => {
        auth.parseHash((error, authResult) => {
            if (error) {
                console.log(error);
                console.log(`Error ${error.error} occured`);
                return;
            }

            this.setSession(authResult.idTokenPayload);
        });
    };

    setSession = (data) => {
        const user = {
            id: data.sub,
            name: data.name,
            firstName: data.given_name,
            lastName: data.family_name,
            email: data.email,
            roles: data[AUTH_CONFIG.roleUrl],
            avatar: data.picture,
        };
        this.setState({
            authenticated: true,
            accessToken: data.accessToken,
            user,
        });
    };

    render = () => {
        const authProviderValue = {
            ...this.state,
            initiateLogin: this.initiateLogin,
            handleAuthentication: this.handleAuthentication,
            logout: this.logout,
            setSession: this.setSession,
        };

        if (process.env.NODE_ENV === "local") {
            console.log("local");
        }

        return (
            <AuthProvider value={authProviderValue}>
                {this.props.children}
            </AuthProvider>
        );
    };
}

export default Auth;
