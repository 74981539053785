import React from "react";
import Can from "../Auth/Can";
import PropTypes from "prop-types";
import ActionEdit from "../Shared/List/ActionEdit";
import ActionActivate from "../Shared/List/ActionActivate";

class Company extends React.Component {
    handleActivateClick = () => {
        alert("activated");
    };

    handleDeactivateClick = () => {
        alert("deactivated");
    };

    render = () => {
        const company = this.props.company;

        return (
            <tr className="company">
                <td>{company.name}</td>
                <td>{company.url}</td>
                <td>{company.accountId}</td>
                <td style={{ textAlign: "center" }}>
                    <Can
                        user={this.props.currentUser}
                        perform="company:update"
                        yes={() => (
                            <ActionEdit to="/company/edit" id={company.id} />
                        )}
                    />
                    <Can
                        user={this.props.currentUser}
                        perform="company:activate"
                        yes={() => (
                            <ActionActivate
                                isActive={true}
                                handleActivate={this.handleActivateClick}
                                handleDeactivate={this.handleDeactivateClick}
                            />
                        )}
                    />
                </td>
            </tr>
        );
    };
}

Company.propTypes = {
    company: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default Company;
