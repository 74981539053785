import React from "react";
import { Link } from "react-router-dom";

const ActionEdit = ({ to, id }) => {
    const target = `${to}/${id}`;
    return (
        <Link to={target} className="action-icon">
            <ion-icon name="cog" />
        </Link>
    );
};

export default ActionEdit;
