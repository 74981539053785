import React from "react";
import PageLayout from "../Shared/PageLayout/PageLayout";
import PageTitle from "../Shared/PageTitle/PageTitle";
import Can from "../Auth/Can";
import Rule from "../Rule/Rule";
import NoResultsRow from "../Shared/NoResultsRow/NoResultsRow";
import ActionCreate from "../Shared/List/ActionCreate";

class RuleList extends React.Component {
    handleFilterTextChange = () => {};

    renderRuleList = ({ currentUser, ...rest }) => {
        const filter = "metal";
        const data = require("../../rules");

        return (
            <div className="rule-list">
                <PageTitle title="Manage Rule">
                    <Can
                        user={currentUser}
                        perform="rule:create"
                        yes={() => <ActionCreate to="/rules/new" name="Rule" />}
                    />
                </PageTitle>
                <div className="filter-wrapper form-inline">
                    <div className="form-group">
                        <label htmlFor="filter">Filter</label>
                        <input
                            name="filter"
                            id="filter"
                            value={filter}
                            onChange={this.handleFilterTextChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Application</th>
                            <th>Name</th>
                            <th>Active</th>
                            <th>Order</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.default.length > 0 ? (
                            data.default.map((rule) => (
                                <Rule
                                    key={rule.id}
                                    rule={rule}
                                    currentUser={currentUser}
                                />
                            ))
                        ) : (
                            <NoResultsRow />
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="rule:manage"
                callback={this.renderRuleList}
            />
        );
    };
}

export default RuleList;
