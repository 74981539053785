import React from "react";
import PageTitle from "../../components/Shared/PageTitle/PageTitle";
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import RuleForm from "./RuleForm";

class RuleEdit extends React.Component {
    handleSubmit = () => {
        alert("handleSubmit");
    };

    renderRuleForm = () => {
        const data = require("../../rules");
        const id = parseInt(this.props.match.params.id);
        const rule = data.default.find((item) => {
            return item.id === id;
        });

        return (
            <div className="user-form add-form">
                <PageTitle title="Edit Rule" />
                <RuleForm rule={rule} handleSubmit={this.handleSubmit} />
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="users:update"
                callback={this.renderRuleForm}
            />
        );
    };
}

export default RuleEdit;
