import React from "react";
import PageTitle from "../../components/Shared/PageTitle/PageTitle";
import PageLayout from "../../components/Shared/PageLayout/PageLayout";
import ApplicationForm from "./ApplicationForm";

class ApplicationEdit extends React.Component {
    handleSubmit = () => {
        alert("handleSubmit");
    };

    renderApplicationForm = ({ currentUser }) => {
        const data = require("../../applications");
        const id = parseInt(this.props.match.params.id);
        const application = data.default.find((app) => {
            return app.id === id;
        });

        return (
            <div className="application-form add-form">
                <PageTitle title="Edit Application" />
                <ApplicationForm
                    application={application}
                    currentUser={currentUser}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        );
    };

    render = () => {
        return (
            <PageLayout
                permission="application:update"
                callback={this.renderApplicationForm}
            />
        );
    };
}

export default ApplicationEdit;
