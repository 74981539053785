import React from "react";

const ActionDelete = () => {
    return (
        <div className="action-icon">
            <ion-icon name="trash" alt="delete" />
        </div>
    );
};

export default ActionDelete;
