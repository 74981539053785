import PropTypes from "prop-types";
import React from "react";
import { ErrorMessage, Field } from "formik";

const Input = ({
    name,
    value,
    label,
    type = "text",
    autoFocus = false,
    labelClass = "",
    inputClass = "",
    placeholder = "",
}) => (
    <div className="form-group">
        <div className={inputClass}>
            <label htmlFor={name} className={`control-label ${labelClass}`}>
                {label}
            </label>
            <Field
                name={name}
                defaultValue={value}
                autoFocus={autoFocus}
                type={type}
                placeholder={placeholder}
                className={`form-control ${inputClass}`}
            />
            <ErrorMessage name={name} />
        </div>
    </div>
);

Input.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    type: PropTypes.oneOf(["text", "email", "password", "tel", "url"]),
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
};

export default Input;
