import React from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import Input from "../../components/Shared/Form/Input";
import * as Yup from "yup";
import CancelButton from "../Shared/Form/CancelButton";
import SaveButton from "../Shared/Form/SaveButton";
import Can from "../Auth/Can";
import Checkbox from "../Shared/List/Checkbox";
import ActionCreate from "../Shared/List/ActionCreate";
import "./ApplicationForm.css";

const validationSchema = {
    name: Yup.string().required(),
};

class ApplicationForm extends React.Component {
    renderRole = (role) => {
        return (
            <tr>
                <td>{role.name}</td>
                <td className="center">{role.diOnly && <Checkbox />}</td>
            </tr>
        );
    };

    renderRoleTable = () => {
        return (
            <div>
                <div className="legend-div">
                    <legend className="legend">Roles</legend>
                    <div className="right">
                        <Can
                            user={this.props.currentUser}
                            perform="role:create"
                            yes={() => (
                                <ActionCreate to="/roles/new" name="Role" />
                            )}
                        />
                    </div>
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>DI Only</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.application.roles.map((role) =>
                            this.renderRole(role)
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    render = () => {
        const { application, handleSubmit } = this.props;
        return (
            <Formik
                initialValues={{ application }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                render={() => {
                    return (
                        <Form className="application-form">
                            <fieldset className="contact-info">
                                <legend>Application</legend>
                                <Input
                                    name="name"
                                    label="Name"
                                    value={application.name}
                                />
                            </fieldset>
                            {this.renderRoleTable()}
                            <div className="button-wrapper">
                                <CancelButton to="/applications" />
                                <SaveButton />
                            </div>
                        </Form>
                    );
                }}
            />
        );
    };
}

ApplicationForm.propTypes = {
    application: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default ApplicationForm;
