const rules = [
    {
        id: 1,
        name: "Division of responsibility",
        isActive: true,
        order: 10,
        applicationId: 1,
        applicationName: "Metal",
        code:
            "if (role.name !== 'FinanceManager'\n&& role.name !== 'FinanceApprover') {\n" +
            "    return;\n" +
            "}\n\n" +
            "if (role.name === 'FinanceManager'\n&& user.roles.includes('FinanceApprover')) {\n" +
            "    throw new Error('This user is already a Finance Approver.');\n" +
            "}\n\n" +
            "if (role.name === 'FinanceApprover'\n&& user.roles.includes('FinanceManager')) {\n" +
            "    throw new Error('This user is already a Finance Manager.');\n" +
            "}\n\n",
    },
    {
        id: 2,
        name: "No more than 2 admins per dealership",
        isActive: true,
        order: 20,
        applicationId: 1,
        applicationName: "Metal",
        code: "",
    },
    {
        id: 3,
        name: "Disallow Group Admin role",
        isActive: false,
        order: 30,
        applicationId: 1,
        applicationName: "Metal",
        code:
            "if (role.name === 'Admin') {\n" +
            "    // this role is not implemented yet\n" +
            "    throw new Error('Role is temporarily disabled.');\n" +
            "}\n\n",
    },
];

export default rules;
