import React from "react";
import PropTypes from "prop-types";

const ActionActivate = ({
    isActive,
    handleActivate = () => {},
    handleDeactivate = () => {},
}) => {
    if (isActive) {
        return (
            <div className="action-icon" onClick={handleDeactivate}>
                <ion-icon name="thumbs-down" alt="deactivate" />
            </div>
        );
    }

    return (
        <div className="action-icon" onClick={handleActivate}>
            <ion-icon name="thumbs-up" alt="activate" />
        </div>
    );
};

ActionActivate.propTypes = {
    isActive: PropTypes.bool.isRequired,
    handleActivate: PropTypes.func,
    handleDeactivate: PropTypes.func,
};

export default ActionActivate;
