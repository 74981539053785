import React from "react";
import "./NavigationHeader.css";

const NavigationHeader = ({ user }) => (
    <header className="header navigation-header">
        <h1>Role Management</h1>
        <div className="currentUser">
            {user.name}
            <img className="header-avatar" src={user.avatar} alt={""} />
        </div>
    </header>
);

export default NavigationHeader;
