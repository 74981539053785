const groups = [
    {
        id: 1,
        name: "Garber Automotive Group",
        url: "https://www.drivegarber.com/",
        accountId: "0013600000Gfz0PAAR",
    },
];

export default groups;
