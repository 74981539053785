const rules = {
    visitor: {
        static: ["home-page:visit"],
    },
    admin: {
        static: [
            "users:list",
            "users:create",
            "users:update",
            "users:delete",
            "users:block",
            "users:grant",
        ],
        dynamic: {
            "posts:edit": (user, { postOwnerId }) => {
                if (!user || !user.id || !postOwnerId) return false;
                return user.id === postOwnerId;
            },
        },
    },
    superAdmin: {
        static: [
            "application:manage",
            "application:create",
            "application:update",
            "application:delete",
            "client:manage",
            "client:create",
            "client:update",
            "client:delete",
            "company:manage",
            "company:create",
            "company:update",
            "company:activate",
            "group:manage",
            "group:create",
            "group:update",
            "group:activate",
            "users:list",
            "users:create",
            "users:update",
            "users:delete",
            "users:block",
            "users:grant",
            "role:manage",
            "role:create",
            "role:update",
            "role:delete",
            "rule:manage",
            "rule:create",
            "rule:update",
            "rule:activate",
            "rule:delete",
        ],
    },
};

export default rules;
